import React, { useCallback } from 'react'
import { graphql } from 'gatsby'
import { PageContextPropsType } from '../../i18n/i18n'
import LayoutBlog from '../components/LayoutBlog'
import classNames from 'classnames/bind'
import * as BlogPageStyles from '../styles/BlogPage.css'
import { BlogArticleDataList, BlogArticleDatas } from '../types/blog'
import { DateTime } from 'luxon'
import blogPaperIcon from '../images/blog/blocks-paper.png'
import BlogAside from '../components/BlogAside'
import BlogHeroArticle from '../components/BlogHeroArticle'
import BlogPagination from '../components/BlogPagination'
import SEO from '../components/SEO'

type BlogPageProps = {
  data: { allNewtBlogArticle: BlogArticleDataList }
} & PageContextPropsType

const cx = classNames.bind(BlogPageStyles)
export default function BlogPage({ data, pageContext }: BlogPageProps) {
  const { allNewtBlogArticle } = data
  const FormatDate = useCallback((date: string) => {
    const Date = DateTime.fromISO(date).setZone('Asia/Tokyo').toFormat('yyyy.MM.dd')
    return Date
  }, [])
  return (
    <LayoutBlog pageContext={pageContext}>
      <SEO
        title="サイト制作・運用・成果改善のコツと最新事例を発信 | KARTE Blocks Blog"
        description="KARTE Blocks Blogは、サイト制作・運用・成果改善のコツとKARTE Blocksの最新事例を発信しています。"
        path="/blog/"
      />
      <BlogHeroArticle />
      <div className={cx('Container')}>
        <div className={cx('Container_Article')}>
          <div className={cx('Container_Article_Head')}>
            <img className={cx('Container_Article_Head_Icon')} src={blogPaperIcon} />
            <h2 className={cx('Container_Article_Head_Text')}>記事一覧</h2>
          </div>
          {allNewtBlogArticle.edges.map((item: BlogArticleDatas) => (
            <a href={`/blog/` + item.node.slug + `/`} className={cx('Container_ArticleItem')} key={item.node._id}>
              <img className={cx('Container_ArticleItem_Image')} src={item.node.thumbnail.src} width="500" />
              <div className={cx('Container_ArticleItem_Content')}>
                <p className={cx('Container_ArticleItem_Date')}>{FormatDate(item.node.meta.metaPublishDate)}</p>
                <p className={cx('Container_ArticleItem_Title')}>{item.node.title}</p>
                <div className={cx('Container_ArticleItem_Tags')}>
                  {item.node.tags.map((tag, index) => (
                    <div key={index} className={cx('Container_ArticleItem_Tag')}>
                      <p>#{tag.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </a>
          ))}
          <BlogPagination page="top" totalCount={allNewtBlogArticle.totalCount} />
        </div>
        <BlogAside />
      </div>
    </LayoutBlog>
  )
}

export const query = graphql`
  query ($_limit: Int!, $_skip: Int!) {
    allNewtBlogArticle(limit: $_limit, sort: { fields: meta___metaPublishDate, order: DESC }, skip: $_skip) {
      totalCount
      edges {
        node {
          _id
          title
          slug
          thumbnail {
            _id
            src
          }
          meta {
            metaPublishDate
          }
          tags {
            _id
            name
            slug
          }
        }
      }
    }
  }
`
