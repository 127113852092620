import React, { useCallback } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { DateTime } from 'luxon'
import * as BlogPageStyles from '../styles/BlogPage.css'
import classNames from 'classnames/bind'
import HeroSubPage from '../components/HeroSubPage'
import { graphql, useStaticQuery } from 'gatsby'
const cx = classNames.bind(BlogPageStyles)

const BlogHeroArticle: React.VFC = () => {
  const FormatDate = useCallback((date: string) => {
    const Date = DateTime.fromISO(date).setZone('Asia/Tokyo').toFormat('yyyy.MM.dd')
    return Date
  }, [])
  const allArticleTagData = useStaticQuery(graphql`
    query {
      allNewtBlogArticle(limit: 1, sort: { fields: meta___metaPublishDate, order: DESC }) {
        totalCount
        edges {
          node {
            _id
            title
            slug
            thumbnail {
              _id
              src
            }
            meta {
              metaPublishDate
            }
            tags {
              _id
              name
              slug
            }
          }
        }
      }
    }
  `)
  const { allNewtBlogArticle } = allArticleTagData
  return (
    <HeroSubPage type="Blog">
      <div className={cx('HeroHead')}>
        <h1 className={cx('HeroTitle')}>KARTE Blocks Blog</h1>
        <p className={cx('HeroLead')}>サイト制作・運用・成果改善のコツと最新事例を発信</p>
      </div>
      {
        <a href={`/blog/` + allNewtBlogArticle.edges[0].node.slug + `/`} className={cx('HeroArticle')}>
          <img className={cx('HeroArticle_Image')} src={allNewtBlogArticle.edges[0].node.thumbnail.src} />
          <div className={cx('HeroArticle_Content')}>
            <p className={cx('HeroArticle_Date')}>{FormatDate(allNewtBlogArticle.edges[0].node.meta.metaPublishDate)}</p>
            <p className={cx('HeroArticle_Title')}>{allNewtBlogArticle.edges[0].node.title}</p>
            <div className={cx('HeroArticle_Tags')}>
              {allNewtBlogArticle.edges[0].node.tags.map((tag: any, index: number) => (
                <div key={index} className={cx('HeroArticle_Tag')}>
                  <p>#{tag.name}</p>
                </div>
              ))}
            </div>
          </div>
        </a>
      }
    </HeroSubPage>
  )
}

export default BlogHeroArticle
